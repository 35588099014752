
import {
  defineComponent, ref
} from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import DlcRegistrationWrapper from '@/components/pages/services/numbers/dlcRegistration/DlcRegistrationWrapper.vue'

export default defineComponent({
  components: {
    DlcRegistrationWrapper,
    TmButton,
  },
  setup() {
    const option = ref('10DLC')

    return {
      option,
    }
  },
})
